import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Button } from '@dragonchain-dev/dragon-kit';
import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import Input from './Input';

import { selectTransactionId, postTransaction } from '../store/reportSlice';
import { selectShowFormHelper, disableFormHelper } from '../store/settingsSlice';
import TwitterSvg from '../assets/icons/twitter.svg';

const Options = ({ selected, setSelected }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const showHelper = useSelector(selectShowFormHelper);

  const handleOptionClick = option => {
    if (showHelper) dispatch(disableFormHelper());
    setSelected(option);
    setOpen(false);
  };

  const handleShowOptions = () => {
    if (showHelper) dispatch(disableFormHelper());
    setOpen(!open);
  };

  const helper = showHelper && (
    <div className="instructions">
      <div className="text">Choose what you save</div>
      <button type="button" onClick={() => dispatch(disableFormHelper())}>
        Ok
      </button>
    </div>
  );

  return (
    <div className={cx('content-type-select', open && 'is-open')}>
      <button type="button" className="value" onClick={() => handleShowOptions()}>
        <div>{selected.text}</div>
      </button>
      <div className="options">
        <div className="inner">
          <button
            type="button"
            className="option"
            onClick={() =>
              handleOptionClick({ type: 'tweet', text: 'Tweet', helper: 'Enter a tweet URL...' })
            }
          >
            Archive a <b>Tweet</b>
          </button>
          <button
            type="button"
            className="option"
            onClick={() =>
              handleOptionClick({ type: 'url', text: 'Web Page', helper: 'Enter a web page URL...' })
            }
          >
            Archive a <b>Web Page</b>
          </button>
          <button
            type="button"
            className="option"
            onClick={() => handleOptionClick({ type: 'ledger', text: 'Text', helper: 'Enter some text...' })}
          >
            Archive some <b>Text</b>
          </button>
          <button
            type="button"
            className="option"
            onClick={() =>
              handleOptionClick({
                type: 'search',
                text: 'Transaction',
                helper: 'Enter a transaction id...',
              })
            }
          >
            Find a <b>Transaction</b>
          </button>
          {/* <button
            type="button"
            className="option"
            onClick={() =>
              handleOptionClick({
                type: 'ledger',
                text: 'Story',
                helper: 'Write a story, we support markdown...',
              })
            }
          >
            Save a <b>Story</b>
          </button> */}
        </div>
      </div>
      {helper}
    </div>
  );
};

const EternalForm = () => {
  const dispatch = useDispatch();
  const transactionId = useSelector(selectTransactionId);
  const [content, setContent] = useState('');
  const [archiving, setArchiving] = useState(false);
  const [selected, setSelected] = useState({ type: 'tweet', text: 'Tweet', helper: 'Enter a tweet URL...' });

  useEffect(() => {
    if (transactionId)
      setTimeout(() => {
        navigate(`/transaction/${transactionId}`);
      }, 5500);
  }, [transactionId]);

  const handlePostContent = () => {
    const options = {
      type: selected.type,
      text: selected.type === 'ledger' ? content : undefined,
      url: selected.type !== 'ledger' ? content : undefined,
    };
    setArchiving(true);
    if (selected.type === 'search') {
      navigate(`/transaction/${content}`);
    } else dispatch(postTransaction(options));
  };

  return (
    <div className="eternal-form">
      <div className="eternal-form-container">
        <Options selected={selected} setSelected={setSelected} />
        {selected.type === 'ledger' ? (
          <div className="text-input">
            <textarea
              name="Content"
              value={content}
              onChange={e => setContent(e.target.value)}
              placeholder={selected.helper}
            />
          </div>
        ) : (
          <Input
            type="text"
            name="Content"
            value={content}
            setValue={setContent}
            placeholder={selected.helper}
          />
        )}

        <Button type="button" onClick={() => handlePostContent()} disabled={archiving}>
          {selected.type === 'search' ? 'Find' : `Archive ${selected.text}`}
          {selected.text === 'Tweet' ? <TwitterSvg className="twitter-icon" /> : <></>}
        </Button>
      </div>
    </div>
  );
};

export default EternalForm;
