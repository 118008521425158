import { format, utcToZonedTime } from 'date-fns-tz';

const tradeFormat = epoch => {
  if (!epoch) return epoch;
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const utcTime = utcToZonedTime(Number(epoch), timeZone);
  return format(utcTime, 'MM/dd/yyyy HH:mm:ss', timeZone);
};

const reportFormat = epoch => {
  if (!epoch) return '';
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const utcTime = utcToZonedTime(Number(epoch), timeZone);
  return format(utcTime, 'MMMM dd, yyyy @ HH:mm zzz', timeZone);
};

const now = () => Math.floor(new Date().getTime() / 1000);

export { tradeFormat, reportFormat, now };
