import React from 'react';

const Lazers = () => {
  return (
    <div className="lazers">
      <div className="inner">
        <div className="lazer pink size-4 anim-1" style={{ left: '12%', top: '7%' }} />
        <div className="lazer pink size-4 anim-4" style={{ left: '38%', top: '12%' }} />
        <div className="lazer pink size-5 anim-3" style={{ left: '86%', top: '53%' }} />
        <div className="lazer cyan size-2 anim-7" style={{ left: '63%', top: '3%' }} />
        <div className="lazer cyan size-2 anim-8" style={{ left: '83%', top: '68%' }} />
        <div className="lazer cyan size-6 anim-2" style={{ left: '1%', top: '83%' }} />
        <div className="lazer cyan size-6 anim-2" style={{ left: '59%', top: '60%' }} />
        <div className="lazer orange size-1 anim-1" style={{ left: '56%', top: '20%' }} />
        <div className="lazer orange size-1 anim-6" style={{ left: '73%', top: '85%' }} />
        <div className="lazer blue size-5 anim-2" style={{ left: '33%', top: '35%' }} />
        <div className="lazer blue size-2 anim-5" style={{ left: '46%', top: '46%' }} />
        <div className="lazer blue size-3 anim-3" style={{ left: '86%', top: '27%' }} />
        <div className="lazer blue size-5 anim-6" style={{ left: '48%', top: '85%' }} />
        <div className="lazer purple size-3 anim-3" style={{ left: '17%', top: '53%' }} />
        <div className="lazer purple size-7 anim-8" style={{ left: '3%', top: '58%' }} />
        <div className="lazer purple size-4 anim-7" style={{ left: '34%', top: '70%' }} />
        <div className="lazer purple size-6 anim-4" style={{ left: '18%', top: '95%' }} />
        <div className="lazer purple size-7 anim-5" style={{ left: '68%', top: '33%' }} />
        <div className="lazer purple size-4 anim-1" style={{ left: '74%', top: '12%' }} />
      </div>
    </div>
  );
};

export default Lazers;
