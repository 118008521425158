import React from 'react';
import Summary from './Summary';
import Transaction from './Transaction';
import Verification from './Verification';

const Report = ({ children }) => {
  return (
    <div className="report-contents">
      <div className="report-header">
        <div>
          <div className="report-title">Eternal Report</div>
        </div>
        <div>
          <p>
            Provable Report powered by&nbsp;
            <a href="https://dragonchain.com" target="_blank">
              Dragonchain
            </a>
          </p>
        </div>
      </div>
      {children}
      <Summary />
      <Transaction />
      <div className="report-verifications">
        <div className="inner">
          <h2 className="title">Blockchain Verifications</h2>
          <Verification level="L1" block />
          <Verification level="L2" />
          <Verification level="L3" />
          <Verification level="L4" />
          <Verification level="L5" />
        </div>
      </div>
    </div>
  );
};

export default Report;
