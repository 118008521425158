import React from 'react';
import { DragonKit, NavHeader, NavBar } from '@dragonchain-dev/dragon-kit';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import Lazers from './Lazers';

import Logo from '../assets/icons/eternal-logo.svg';

import '../assets/styles/main.scss';
import SEO from './SEO';

const Layout = ({ children }) => {
  return (
    <DragonKit Link={Link} theme="dark" Img={Img}>
      <NavHeader Logo={Logo}>
        <NavBar links={[]} />
      </NavHeader>
      <SEO />
      <main>
        <Lazers />
        {children}
      </main>
      <footer className="eternal-footer">
        <div className="inner">
          <div className="logo">
            <Logo />
          </div>
          <div className="info">
            <p>
              Copyright © 2020{' '}
              <a href="https://dragonchain.com" target="__blank">
                Dragonchain
              </a>
              , Inc.
              <br />
              All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </DragonKit>
  );
};

export default Layout;
