import React, { useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { reportFormat } from '../../utils/dateUtils';
import Table from '../Table';
import { getHashPower, selectSummary } from '../../store/reportSlice';

import BTCIcon from '../../assets/icons/network-BTC.svg';
import ETHIcon from '../../assets/icons/network-ETH.svg';
import ETCIcon from '../../assets/icons/network-ETC.svg';
import BNBIcon from '../../assets/icons/network-BNB.svg';
import intWithSeparator from '../../utils/intWithSeparator';

const Verifications = ({ verifications }) => {
  return (
    <Table.Column>
      <div className="label">Total Verifications</div>
      {/* <div className="value light">5 (L2: 1 minute, L3: 2 minutes, L4: 4 minutes, L5: Pending)</div> */}
      <div className="dragon-net-visualization">
        {verifications.map(l => {
          const level = Object.keys(l)[0];
          const count = l[level] || 1;
          return (
            <div key={level} className="dragon-net-level-group">
              {Array(count)
                .fill()
                .map((_, index) => (
                  <div
                    key={`${level}-${index}`}
                    className={cx('dragon-net-level', l[level] > 0 && 'complete')}
                  >
                    <span>{level}</span>
                  </div>
                ))}
            </div>
          );
        })}
      </div>
    </Table.Column>
  );
};
const SecuredBy = ({ networks }) => {
  const networkNames = {
    BTC: 'Bitcoin',
    ETH: 'Ethereum',
    ETC: 'Ethereum Classic',
    BNB: 'Binance Chain',
    BSC: 'Binance Chain',
  };
  const icons = { BTC: BTCIcon, ETH: ETHIcon, ETC: ETCIcon, BNB: BNBIcon, BSC: BNBIcon };
  if (!networks.length) return <></>;
  return (
    <Table.Column>
      <div className="label">This Transaction Secured By</div>
      <div className="network-list">
        {Object.keys(networks).map((key, i) => {
          const Icon = icons[networks[key].network];
          return (
            <a
              key={`${networks[key].network}-${i}`}
              className="network-list-item"
              href={networks[key].url}
              target="_blank"
            >
              <div className="network-icon">
                <Icon />
              </div>
              <div className="network-name">
                <b>{networkNames[networks[key].network]}</b>
                &nbsp;<span className="light">({networks[key].network})</span>
              </div>
              <div className="network-list-txn">TXN</div>
            </a>
          );
        })}
      </div>
    </Table.Column>
  );
};

const HashPower = ({ securedBy: { networks, value }, hashPower }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!networks.length) return;
    dispatch(getHashPower({ networks }));
  }, [networks]);

  const cumulativeHashPower =
    hashPower.combined.value && `${hashPower.combined.value} ${hashPower.combined.units}`;
  if (!networks.length) return <></>;
  return (
    <Table.Column>
      <div className="label">Cumulative Hash Power</div>
      <div className="value">{cumulativeHashPower}</div>

      <div className="label">Transaction Security Value</div>
      <div className="value">{value}</div>
    </Table.Column>
  );
};

const Summary = () => {
  const summary = useSelector(selectSummary);

  return (
    <div className="report-summary">
      <Table type="report">
        <Table.Row className="light">
          <Table.Column>
            <div className="value">{reportFormat(Number(summary.timestamp) * 1000)}</div>
          </Table.Column>
        </Table.Row>
        <Table.Row>
          <Table.Column>
            <div className="label">Transaction ID</div>
            <div className="value">{summary.transactionId}</div>
          </Table.Column>
        </Table.Row>
        <Table.Row>
          <Table.Column>
            <div className="label">Transaction Type</div>
            <div className="value">{summary.transactionType}</div>
          </Table.Column>
        </Table.Row>
        <Table.Row>
          <Verifications verifications={summary.verifications} />
        </Table.Row>
        <Table.Row>
          <SecuredBy networks={summary.securedBy.networks} />
        </Table.Row>
        <Table.Row>
          <HashPower securedBy={summary.securedBy} hashPower={summary.hashPower} />
        </Table.Row>
        <Table.Row>
          <Table.Column>
            <div className="label">Applied Time</div>
            <div className="value">
              {summary.appliedTime && `${intWithSeparator(summary.appliedTime)} TIME`}
            </div>
          </Table.Column>
        </Table.Row>
      </Table>
    </div>
  );
};

export default Summary;
