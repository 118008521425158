import React from 'react';
import { useSelector } from 'react-redux';
import { selectDownload } from '../../store/reportSlice';

const Download = () => {
  const report = useSelector(selectDownload);
  return (
    <a
      className="pill-button purple outline"
      download={`${report.summary.transactionId}.json`}
      href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(report, undefined, 2))}`}
    >
      Download Report
    </a>
  );
};

export default Download;
