import React from 'react';
import { useSelector } from 'react-redux';
import RawResponse from './RawResponse';
import Table from '../Table';
import { reportFormat } from '../../utils/dateUtils';
import { selectTransaction } from '../../store/reportSlice';

const Transaction = () => {
  const transaction = useSelector(selectTransaction);

  return (
    <div className="report-transaction">
      <div className="inner">
        <h2 className="title">Transaction</h2>
        <h3 className="subtitle">Transaction Information</h3>
        <Table type="report-data">
          <Table.Row className="light">
            <Table.Column header min>
              Resource Name
            </Table.Column>
            <Table.Column>{transaction.dcrn}</Table.Column>
          </Table.Row>
          <Table.Row className="light">
            <Table.Column header min>
              Transaction ID
            </Table.Column>
            <Table.Column>{transaction.header.txn_id}</Table.Column>
          </Table.Row>
          <Table.Row className="light">
            <Table.Column header min>
              Block ID
            </Table.Column>
            <Table.Column>{transaction.header.block_id}</Table.Column>
          </Table.Row>
          <Table.Row className="light">
            <Table.Column header min>
              Timestamp
            </Table.Column>
            <Table.Column>{reportFormat(Number(transaction.header.timestamp) * 1000)}</Table.Column>
          </Table.Row>
        </Table>
        <RawResponse json={transaction} />
      </div>
    </div>
  );
};

export default Transaction;
