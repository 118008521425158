import React from 'react';

const nodeInfo = {
  L1: {
    title: 'L1 nodes: sensitive data and business logic',
    description:
      'This is the placement for integrations of “real world” value in the form of transactions and business logic. This information is configured by the business or blockchain user and executed by a blockchain node.',
  },
  L2: {
    title: 'L2 nodes: validation of block, header, and signatures',
    description:
      'Level 2 nodes are a trusted check of validity for blocks and individual transactions which can be done without exposing the actual data. Rules for L2 nodes are defined at the enterprise level.',
  },
  L3: {
    title: 'L3 nodes: network diversity of L2 validations',
    description:
      'Level 3 nodes check the diversity of a transaction’s Level 2 verifications. This level of consensus will ensure that transactions are validated by a sufficiently varied set of distributed sources.',
  },
  L4: {
    title: 'L4 nodes: notary function',
    description:
      'A Level 4 node provides a notary functionality to the consensus process. Hosted by an external partner, a Level 4 node cryptographically signs any verification record it receives from Level 3. This function allows the Level 4 node to act as an independent witness to Level 3 verifications.',
  },
  L5: {
    title: 'L5 nodes: public checkpoint',
    description:
      'Level 5 node serves as the gateway between the Dragonchain platform and public blockchains. When a transaction passes through a Level 5 verification, it can then be hosted on a public chain like ETH, ETC, or BTC.',
  },
};

const VerificationHeader = ({ level }) => {
  return (
    <div className="verification-header">
      <div className="level">{level}</div>
      <div className="content">
        <div className="inner">
          <h3 className="title">{nodeInfo[level].title}</h3>
          <p>{nodeInfo[level].description}</p>
        </div>
      </div>
    </div>
  );
};

export default VerificationHeader;
