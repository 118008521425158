import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ lang, noFollow, title, description, meta, cardType }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.title;

  if (noFollow) {
    return (
      <Helmet
        htmlAttributes={{ lang }}
        title={metaTitle}
        meta={[
          { name: `description`, content: metaDescription },
          { property: `robots`, content: `noindex, nofollow` },
        ]}
      />
    );
  }

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={metaTitle}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        { name: `description`, content: metaDescription },
        { property: `og:title`, content: metaTitle },
        { property: `og:description`, content: metaDescription },
        { property: `og:type`, content: `website` },
        { name: `twitter:title`, content: metaTitle },
        { name: `twitter:description`, content: metaDescription },
        { name: `twitter:card`, content: cardType },
        { name: `twitter:image`, content: `https://eternal.report/images/eternal-social.png` },
        { name: `og:image`, content: `https://eternal.report/images/eternal-social.png` },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  noFollow: false,
  description: ``,

  cardType: `summary_large_image`,
  title: '',
};

SEO.propTypes = {
  noFollow: PropTypes.bool,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,

  cardType: PropTypes.oneOf(['summary', 'summary_large_image']),
};

export default SEO;
