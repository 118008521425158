/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ReactMarkdown from 'react-markdown';

import TwitterIcon from '../assets/icons/twitter.svg';

const DeletedTweet = ({ mediaMarkup, tweet, text }) => {
  const { display_name: displayName, user, created_at: createdAt } = tweet;

  const tweetText = text.split(`— ${displayName}`)[0];
  const handle = user.substring(1);

  const parseTimestamp = timestamp => new Date(parseInt(timestamp, 10) * 1000);

  const dateString = parseTimestamp(createdAt).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  const timeString = parseTimestamp(createdAt).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  });

  return (
    <div className="deleted-tweet-card-wrapper">
      <div className="deleted-tweet-card-container">
        <div className="deleted-tweet-card">
          <TwitterIcon className="twitter-icon" />
          <div className="twitter-header">
            <a className="profile-image">
              <img src={`https://unavatar.now.sh/twitter/${handle}`} alt="" height={36} align="right" />
            </a>
            <div className="author">
              <span>
                <a href={`https://twitter.com/${handle}`} className="twitter-name" target="_blank">
                  {displayName}
                </a>
              </span>
              <a className="twitter-handle" href={`https://twitter.com/${handle}`}>
                {user}
              </a>
            </div>
          </div>
          <p className="tweet">
            <ReactMarkdown source={tweetText} />
          </p>
          {mediaMarkup && mediaMarkup}
          <span className="twitter-time">
            {timeString} - {dateString}
          </span>
        </div>
      </div>
      <div className="deleted-tweet-notice">
        <p>This tweet has been deleted</p>
      </div>
    </div>
  );
};

export default DeletedTweet;
