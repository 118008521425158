import React from 'react';

const Input = ({ type, icon: Icon, name, placeholder, value, setValue, children }) => {
  return (
    <div className="text-input">
      {Icon && (
        <div className="icon">
          <Icon />
        </div>
      )}
      <input type={type} onChange={e => setValue(e.target.value)} {...{ value, placeholder, name }} />
      {children}
    </div>
  );
};

export default Input;
