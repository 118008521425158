import React from 'react';
import { useSelector } from 'react-redux';
import RawResponse from './RawResponse';
import Table from '../Table';
import VerificationHeader from './VerificationHeader';
import { reportFormat } from '../../utils/dateUtils';
import intWithSeparator from '../../utils/intWithSeparator';
import { selectBlock, selectVerifications, selectSummary } from '../../store/reportSlice';

const VerificationBody = ({ level, data }) => {
  const { securedBy, hashPower } = level === 'L5' && useSelector(selectSummary);
  const publicChain = level === 'L5' && securedBy.networks.find(s => s.blockId === data.header.block_id);
  const networkHashPower = level === 'L5' && hashPower.networks.find(s => s.network === publicChain.network);

  return (
    <div className="verification-body">
      <div className="subtitle">{`${level} Node Block`}</div>
      <Table type="report-data">
        <Table.Row className="light">
          <Table.Column header min>
            Resource Name
          </Table.Column>
          <Table.Column>{data.dcrn}</Table.Column>
        </Table.Row>
        <Table.Row className="light">
          <Table.Column header min>
            Block ID
          </Table.Column>
          <Table.Column>{data.header.block_id}</Table.Column>
        </Table.Row>
        {level !== 'L1' && (
          <Table.Row className="light">
            <Table.Column header min>
              Contemporaneous Time
            </Table.Column>
            <Table.Column>
              {data.header.current_ddss && intWithSeparator(data.header.current_ddss)}
            </Table.Column>
          </Table.Row>
        )}
        <Table.Row className="light">
          <Table.Column header min>
            Timestamp
          </Table.Column>
          <Table.Column>{reportFormat(Number(data.header.timestamp) * 1000)}</Table.Column>
        </Table.Row>
        <Table.Row className="light">
          <Table.Column header min>
            Previous Proof
          </Table.Column>
          <Table.Column className="word-break">{data.proof.proof}</Table.Column>
        </Table.Row>
        {level === 'L5' && publicChain && (
          <>
            <Table.Row className="light">
              <Table.Column header min>
                Network
              </Table.Column>
              <Table.Column>{publicChain.network}</Table.Column>
            </Table.Row>
            <Table.Row className="light">
              <Table.Column header min>
                Hash Power
              </Table.Column>
              {!!networkHashPower && (
                <Table.Column>
                  {`${intWithSeparator(networkHashPower.totalHash)} ${networkHashPower.units}`}
                </Table.Column>
              )}
            </Table.Row>
            <Table.Row className="light">
              <Table.Column header min>
                Transaction Hash
              </Table.Column>
              <Table.Column>
                <a href={publicChain.url} target="_blank" className="word-break">
                  {data.proof.transaction_hash[0]}
                </a>
              </Table.Column>
            </Table.Row>
          </>
        )}
      </Table>
      <RawResponse json={data} />
    </div>
  );
};

const Verification = ({ levelKey, level, block = false }) => {
  const data = block ? useSelector(selectBlock) : useSelector(selectVerifications)[level];

  return (
    <div key={levelKey} className="report-verification">
      <VerificationHeader level={level} />
      {block ? (
        <VerificationBody key="verification" level={level} data={data} />
      ) : (
        data.map((verification, index) => (
          <VerificationBody key={`${verification.dcrn}-${index}`} level={level} data={verification} />
        ))
      )}
    </div>
  );
};

export default Verification;
