import React from 'react';

const Item = ({ children }) => {
  return <div className="filter-item">{children}</div>;
};
const Filters = ({ children }) => {
  return (
    <div className="filters">
      <div className="inner">{children}</div>
    </div>
  );
};

Filters.Item = Item;
export default Filters;
