/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import ReactPlayer from 'react-player';
import DeletedTweet from './DeletedTweet';

const S3BucketUrl = 'https://eternal-media.api.dragonchain.com'; // TODO: move

const getMediaMarkup = (type, url) => {
  const mediaUrl = `${S3BucketUrl}/${url}`;
  switch (type) {
    case 'image':
      return <img alt="User media" src={mediaUrl} />;
    case 'video':
      return <ReactPlayer url={mediaUrl} controls className="video-player" />;
    default:
      return null;
  }
};

const getMediaType = url => {
  let type = null;
  const splitUrl = url.split('.');
  const fileType = splitUrl[splitUrl.length - 1];
  if (['jpg', 'png', 'gif', 'webp'].includes(fileType)) type = 'image';
  if (['mp4', 'mov'].includes(fileType)) type = 'video';
  return type;
};

const displayRemovedTweet = payload => {
  const { display_name: displayName, media_urls: media } = payload;
  const mediaUrl = media && media.length ? media[0] : null;
  let mediaMarkup = null;
  if (mediaUrl) {
    const type = getMediaType(mediaUrl);
    switch (type) {
      case 'image':
        mediaMarkup = getMediaMarkup('image', mediaUrl);
        break;
      case 'video':
        mediaMarkup = getMediaMarkup('video', mediaUrl);
        break;
      default:
        break;
    }
  }
  const ytPattern = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/;
  const matches = payload.tweet.match(ytPattern);
  if (matches) mediaMarkup = getMediaMarkup('video', matches[2]);

  return (
    <DeletedTweet
      tweet={payload}
      text={matches ? payload.tweet.replace(`${matches[0]}`, '') : payload.tweet}
      displayName={displayName}
      mediaMarkup={mediaMarkup}
    />
  );
};

const TweetContent = ({ payload }) => {
  const [deleted, setDeleted] = useState(false);
  const action = data => {
    if (!data) setDeleted(true);
  };
  return (
    <>
      {payload.tweet_id && !deleted && (
        <TwitterTweetEmbed onLoad={action} options={{ width: 'unset' }} tweetId={payload.tweet_id} />
      )}
      {deleted && displayRemovedTweet(payload)}
    </>
  );
};

export default TweetContent;
