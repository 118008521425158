import React, { useState } from 'react';
import { PillButton } from '@dragonchain-dev/dragon-kit';

const RawResponse = ({ json }) => {
  const [display, setDisplay] = useState(false);
  return (
    <>
      <PillButton
        type="button"
        outline
        color="purple-on-white"
        hoverColor="purple"
        onClick={() => setDisplay(!display)}
      >
        Show Raw Data
      </PillButton>
      {display && (
        <div className="report-transaction-data">
          <pre>
            <code>{JSON.stringify(json, undefined, 2)}</code>
          </pre>
        </div>
      )}
    </>
  );
};

export default RawResponse;
