import React from 'react';
import cx from 'classnames';

const Column = ({ min, header, center, right, className, children }) => {
  const classes = cx(
    header ? 'th' : 'td',
    min && 'min',
    center && 'center',
    right && 'right',
    className && className,
  );

  return <div className={classes}>{children}</div>;
};

const Row = ({ className, header, children, ...rest }) => {
  const classes = cx('tr', header && 'thead', className && className);
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

const Table = ({ type, className, children }) => {
  const classes = cx('table-wrapper', type && `${type}-table`, className && className);
  return (
    <div className={classes}>
      <div className="table">{children}</div>
    </div>
  );
};

Table.Column = Column;
Table.Row = Row;
export default Table;
